import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitterModule } from 'primeng/splitter';
import { TabViewModule } from 'primeng/tabview';
import { DataViewModule } from 'primeng/dataview';
import { PaginatorModule } from 'primeng/paginator';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { ListboxModule } from 'primeng/listbox';
import { GovBrAlertMessagesModule, GovBrSecondTabModule, GovBrSwitchModule, GovBrTabModule } from '@serpro/ngx-dsgovbr';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TooltipModule } from 'primeng/tooltip';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { CarouselModule } from 'primeng/carousel';
import { ChipsModule } from 'primeng/chips';
import { CalendarModule } from 'primeng/calendar';

import { PipeModule } from '../pipe/pipe.module';
import { GovBrModule } from '../dsgovbr.module';
import { ErroComponent } from './erro/erro.component';

import { CatalogadorComponent } from './catalogador/busca-catalogador/catalogador.component';
// import { ArealogadaComponent } from './arealogada/arealogada.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArealogadaRoutingModule } from './arealogada-routing.module';
import { PaginaModule } from '../shared/pagina/pagina.module';
import { IniciarSessaoComponent } from './iniciar-sessao/iniciar-sessao.component';
import { LoginFakeComponent } from './login-fake/login-fake.component';
import { DropdownModule } from 'primeng/dropdown';
import { SessaoEncerradaComponent } from './sessao-encerrada/sessao-encerrada.component';
import { CompradorComponent } from './comprador/comprador.component';
import { BuscaComponentComponent } from './components/busca/busca-component.component';
import { ResultadoComponent } from './components/resultado/resultado.component';
import { MenuModule } from 'primeng/menu';
import { ManterMaterialComponent } from './catalogador/manter-material/manter-material.component';
import { ManterServicoComponent } from './catalogador/manter-servico/manter-servico.component';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ManterCaracteristicaComponent } from './catalogador/manter-caracteristica/manter-caracteristica.component';
import { CriarMaterialComponent } from './catalogador/criar-material/criar-material.component';
import { ManterValorCaracteristicaComponent } from './catalogador/manter-valor-caracteristica/manter-valor-caracteristica.component';
import { ManterUnidadeFornecimentoComponent } from './catalogador/manter-unidade-fornecimento/manter-unidade-fornecimento.component';
import { ManterUnidadeMedidaComponent } from './catalogador/manter-unidade-medida/manter-unidade-medida.component';
import { BuscaMaterialComponent } from './catalogador/busca-material/busca-material.component';
import { VisualizarMaterialComponent } from './catalogador/visualizar-material/visualizar-material.component';
import { SelecionarCaracteristicaComponent } from './catalogador/components/selecionar-caracteristica/selecionar-caracteristica.component';
import { SelecionarClasseComponent } from './catalogador/components/selecionar-classe/selecionar-classe.component';
import { SelecionarValoresCaracteristicaComponent } from './catalogador/components/selecionar-valores-caracteristica/selecionar-valores-caracteristica.component';
import { SelecionarConjuntoComponent } from './catalogador/components/selecionar-conjunto/selecionar-conjunto.component';
import { SelecionarUnidadeMedidaComponent } from './catalogador/components/selecionar-unidade-medida/selecionar-unidade-medida.component';
import { AdicionarUnidadeFornecimentoComponent } from './catalogador/components/adicionar-unidade-fornecimento/adicionar-unidade-fornecimento.component';
import { SelecionarUnidadeFornecimentoComponent } from './catalogador/components/selecionar-unidade-fornecimento/selecionar-unidade-fornecimento.component';
import { SelecionarUnidadeMedidaFormComponent } from './catalogador/components/selecionar-unidade-medida-form/selecionar-unidade-medida-form.component';
import { BuscaServicoComponent } from './catalogador/busca-servico/busca-servico.component';
import { CriarServicoComponent } from './catalogador/criar-servico/criar-servico.component';
import { VisualizarServicoComponent } from './catalogador/visualizar-servico/visualizar-servico.component';
import { ManterItemMaterialComponent } from './catalogador/manter-item-material/manter-item-material.component';
import { SelecionarCpcComponent } from './catalogador/components/selecionar-cpc/selecionar-cpc.component';
import { ManutencaoCaracteristicaComponent } from './catalogador/manter-caracteristica/manutencao-caracterisca/manutencao-caracteristica/manutencao-caracteristica.component';
import { ManutencaoValorCaracteristicaComponent } from './catalogador/manter-valor-caracteristica/manutencao-valor-caracteristica/manutencao-valor-caracteristica.component';
import { TabelaPedidosComponent } from './comprador/tabela-pedidos/tabela-pedidos/tabela-pedidos.component';
import { ManterPedidosComponent } from './comprador/manter-pedidos/manter-pedidos.component';
import { HistoricoAnaliseComponent } from './comprador/historico-analise/historico-analise.component';
// import { PedidoCatalogadorComponent } from './catalogador/Pedidos/pedido-catalogador/pedido-catalogador.component';
// import { TabelaPedidosCatalogadorComponent } from './catalogador/Pedidos/tabela-pedidos-catalogador/tabela-pedidos-catalogador.component';
// import { ManterPedidosCatalogadorComponent } from './catalogador/Pedidos/manter-pedidos-catalogador/manter-pedidos-catalogador.component';
import { ToastModule } from 'primeng/toast';
import { HistoricoPedidoComponent } from './pedido/historico-pedido/historico-pedido.component';
import { AnexosPedidoComponent } from './pedido/anexos-pedido/anexos-pedido.component';
import { HistoricoAnalisePedidoComponent } from './pedido/historico-analise-pedido/historico-analise-pedido.component';
import { AreaCatalogadorComponent } from './catalogador/area-catalogador/area-catalogador.component';
import { NovasTabelasPedidosCatalogadorComponent } from './catalogador/area-catalogador/tabela-pedidos/tabela-pedidos.component';
import { DesignarPedidoComponent } from './pedido/designar-pedido/designar-pedido.component';
import { PedidoComponent } from './pedido/pedido.component';
import { MenuPedidoComponent } from './pedido/menu-pedido/menu-pedido.component';
import { InformacoesPedidoComponent } from './pedido/informacoes-pedido/informacoes-pedido.component';

import { SharedModule } from '../shared/shared.module';
import { ManterUasgsComponent } from './catalogador/manter-uasgs/manter-uasgs.component';
import { ManterCatalogadorComponent } from './catalogador/manter-catalogador/manter-catalogador.component';


@NgModule({
  declarations: [
    CatalogadorComponent,
    // ArealogadaComponent,
    IniciarSessaoComponent,
    LoginFakeComponent,
    SessaoEncerradaComponent,
    CompradorComponent,
    BuscaComponentComponent,
    ResultadoComponent,
    ManterMaterialComponent,
    ManterServicoComponent,
    ManterCaracteristicaComponent,
    CriarMaterialComponent,
    ManterValorCaracteristicaComponent,
    ManterUnidadeFornecimentoComponent,
    ManterUnidadeMedidaComponent,
    BuscaMaterialComponent,
    VisualizarMaterialComponent,
    SelecionarCaracteristicaComponent,
    SelecionarClasseComponent,
    SelecionarCaracteristicaComponent,
    SelecionarValoresCaracteristicaComponent,
    SelecionarConjuntoComponent,
    SelecionarUnidadeMedidaComponent,
    AdicionarUnidadeFornecimentoComponent,
    SelecionarUnidadeFornecimentoComponent,
    SelecionarUnidadeMedidaFormComponent,
    BuscaServicoComponent,
    CriarServicoComponent,
    VisualizarServicoComponent,
    ManterItemMaterialComponent,
    SelecionarCpcComponent,
    ManutencaoCaracteristicaComponent,
    ManutencaoValorCaracteristicaComponent,
    TabelaPedidosComponent,
    ManterPedidosComponent,
    HistoricoAnaliseComponent,
    // PedidoCatalogadorComponent,
    // TabelaPedidosCatalogadorComponent,
    // ManterPedidosCatalogadorComponent,
    HistoricoPedidoComponent,
    AnexosPedidoComponent,
    HistoricoAnalisePedidoComponent,
    AreaCatalogadorComponent,
    NovasTabelasPedidosCatalogadorComponent,
    DesignarPedidoComponent,
    PedidoComponent,
    MenuPedidoComponent,
    InformacoesPedidoComponent,
    ManterUasgsComponent,
    ManterCatalogadorComponent
  ],
    imports: [
        CommonModule,
        // HttpClientModule,
        FormsModule,
        DropdownModule,
        SplitterModule,
        TabViewModule,
        DataViewModule,
        PaginatorModule,
        MultiSelectModule,
        RadioButtonModule,
        TableModule,
        ListboxModule,
        GovBrAlertMessagesModule,
        GovBrModule,
        AutoCompleteModule,
        SidebarModule,
        SplitButtonModule,
        PipeModule,
        TooltipModule,
        PaginaModule,
        MenuModule,
        GovBrSwitchModule,
        ReactiveFormsModule,
        InputMaskModule,
        InputNumberModule,
        ConfirmPopupModule,
        ConfirmDialogModule,
        GovBrTabModule,
        GovBrSecondTabModule,
        CarouselModule,
        ChipsModule,
        ToastModule,
        CalendarModule,
        SharedModule
    ],
  exports: [
    CatalogadorComponent,
    // ArealogadaComponent,
    ArealogadaRoutingModule,
    CompradorComponent,
    ManterMaterialComponent,
    ManterServicoComponent,
    ManterCaracteristicaComponent,
    ManterValorCaracteristicaComponent,
    InputTextModule,
    ManterUnidadeFornecimentoComponent,
    ManterUnidadeMedidaComponent
  ]
})
export class ArealogadaModule { }
