<!-- Página: informacoes-pedido-component.html -->
<div class="col">
  <div class="d-flex flex-wrap">
    <div class="">
      <h3 class="m-0 pt-2 pb-2" aria-label="Informações Básicas">Informações Básicas</h3>
    </div>

    <!-- Seletores -->
    <!--(Analisador+)-->
    <div class="d-flex ml-auto align-items-center" *ngIf="usrRoles['ANALISADOR']">
      <div class="pt-2 pb-2 mr-2" style="width:250px;" *ngIf="(novaSituacao.value=='DESIGNADO' || novaSituacao.value==='ABERTO')">
        <app-designar-pedido
          [numeroPedido]="pedido.numeroPedido"
          [estadoSelecionado]="novaSituacao.value"
          [currentCpfCatalogador]="pedido.cpfCatalogador"
          [currentCodigoUasg]="pedido.codigoUasgCatalogador"
          ></app-designar-pedido>
      </div>

      <!-- Seleção do motivo da finalização -->
      <div class="pt-2 pb-2 mr-2" style="width:180px;" *ngIf="!pedido.motivoFinalizacao && (novaSituacao.value=='FINALIZADO') && (pedido.motivosPossiveis || pedido.motivoFinalizacao)">
        <label class="mb-0">Motivo da finalização</label>
        <select [(ngModel)]="motivoFinalizacao.value" class="custom-select select-input" aria-label="Motivo da finalização">
          <option [ngValue]="null" [disabled]="true" >-- Selecione --</option>
          <option *ngFor="let motivo of pedido.motivosPossiveis" [value]="motivo.value">{{ motivo.descricao }}</option>
        </select>
      </div>

        <!-- Seleção da nova situação -->
      <div class="pt-2 pb-2" style="width:auto; max-width:220px" *ngIf="!pedido.motivoFinalizacao">
        <label class="mb-0">Situação</label>
        <select [(ngModel)]="novaSituacao.value" class="custom-select select-input" aria-label="Situação">
          <option [value]="pedido.situacaoPedido">{{ pedido.descricaoSituacaoPedido }}</option>
          <option *ngFor="let situacao of pedido.situacoesPossiveis" [value]="situacao.value">{{ situacao.descricao }}</option>
        </select>
      </div>

      <div class="pl-2 pt-3 pb-2" *ngIf="pedido.situacaoPedido!=='FINALIZADO'">
        <button type="button" class="br-button is-primary small" (click)="salvarPedido()" aria-label="Enviar">
          Enviar
        </button>
      </div>
    </div>

    <!-- (Solicitante) -->
    <div class="mr-auto pl-5 pt-3 pb-2" *ngIf="pedido.situacaoPedido==='RASCUNHO'">
      <button type="button" class="br-button small" (click)="salvarPedido()" aria-label="Salvar Rascunho">
        Salvar rascunho
      </button>
    </div>
    <div class="d-flex ml-auto" *ngIf="usrProfile==='SOLICITANTE'">
      <div class="pl-3 pt-3 pb-2" *ngIf="pedido.situacaoPedido==='RASCUNHO' || pedido.situacaoPedido==='AGUARDANDO_SOLICITANTE'">
        <button type="button" pTooltip="Clique aqui para enviar o pedido de catalogação" class="br-button is-primary small" (click)="enviarPedido()" aria-label="Enviar">
          Enviar
        </button>
      </div>
      <div class="pl-3 pt-3 pb-2" *ngIf="pedido.situacaoPedido==='RASCUNHO' || pedido.situacaoPedido==='AGUARDANDO_SOLICITANTE' || pedido.situacaoPedido==='ABERTO'">
        <button type="button" class="br-button secondary cancelar small" (click)="cancelarPedido()" aria-label="Cancelar">
          Cancelar
        </button>
      </div>
    </div>
  </div>

<div class="br-divider"></div>


<div *ngIf="pedido.situacaoPedido==='RASCUNHO'">
  <!-- Seletor inicial de uasg -->
  <div class="d-flex flex-wrap">
    <div class="mr-5">
      <h5 class="m-0 pt-2 pb-2">Selecione a Unidade Catalogadora<span class="asterisco">*</span></h5>
    </div>
    <div class="d-flex">
      <div class="pt-2 pb-2 mr-2" style="width:250px;" >
        <select [(ngModel)]="pedido.codigoUasgCatalogador" pTooltip="Selecione a unidade catalogadora" class="custom-select select-input" aria-label="Selecione a unidade catalogadora" required>
          <option [ngValue]="null" [disabled]="true">-- Selecione --</option>
          <option *ngFor="let uasg of uasgsCatalogadoras" [ngValue]="uasg.codigoUasg">{{uasg.nomeDesignacao}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="br-divider"></div>
</div>




<div class="row mt-3">
  <div class="col-sm-4">
      <label>Número</label>
      <div style="font-weight: normal">
          {{pedido.numeroPedido}}
      </div>
  </div>
  <div class="col-sm-4">
    <label>Unidade Solicitante</label>
    <div style="font-weight: normal">
        {{pedido.codigoUasgSolicitante}} - {{pedido.nomeUasgSolicitante}}
    </div>
  </div>
  <div class="col-sm-4">
    <label>Situação Atual</label>
    <div class="d-flex align-items-center">
      <span [class]="'br-tag ' + getClassSituacao()">
        <span id="densidade01">{{ pedido.descricaoSituacaoPedido }} {{pedido.descricaoMotivoFinalizacao ? (' - ' + pedido.descricaoMotivoFinalizacao) : ''}}</span>
      </span>
    </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col-sm-4">
      <label>Responsável</label>
      <br>
      <div style="font-weight: normal">
        {{pedido.nomeSolicitante}}
      </div>
  </div>

  <div class="col-sm-4">
    <label>Email</label><span class="asterisco">*</span>
    <div class="br-input" style="font-weight: normal">
        <input
          class="p-inputtext"
          placeholder="Entre com o Email"
          [(ngModel)]="pedido.emailSolicitante"
          maxlength="200"
          [readonly] ="formularioReadonly()"
          pTooltip="Informe o seu email."
          required
          aria-required="true"
          />
    </div>
  </div>

  <div class="col-sm-3">
    <label>Telefone</label>
    <div class="br-input" style="font-weight: normal">
        <input
          (keyup)="formataTelefone()"
          class="p-inputtext"
          placeholder="Entre com o Telefone"
          [(ngModel)]="pedido.telefoneSolicitante"
          maxLength="15"
          pTooltip="Informe o telefone"
          [readonly] ="formularioReadonly()"
          />
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="col-sm-12">
    <label *ngIf="pedido.tipoPedido === 'M'">Nome do Material</label>
    <label *ngIf="pedido.tipoPedido === 'S'">Nome do Serviço</label>
    <span class="asterisco">*</span>
    <span *ngIf="pedido.situacaoPedido==='RASCUNHO'"><label class="texto-direita">Caracteres restantes: <span>{{numMaxNome}}</span></label></span>
    <div class="br-input" style="font-weight: normal">
      <input
        #nome
        class="p-inputtext"
        name="nome"
        placeholder="Entre com o nome"
        [(ngModel)]="pedido.nomePedido"
        required
        aria-required="true"
        [readonly] ="formularioReadonly()"
        maxlength="200"
        (keyup)="pressEnterNome($event, nome.value)"
        />
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col-sm-6">
    <label>Tipo</label>
    <div style="font-weight: normal">
      <div *ngIf="pedido.tipoPedido === 'M'">Material</div>
      <div *ngIf="pedido.tipoPedido === 'S'">Serviço</div>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col-sm-12">
    <label pTooltip="Informe os detalhes do pedido de catalogação">Detalhes do Pedido</label><span class="asterisco">*</span>
    <span *ngIf="pedido.situacaoPedido==='RASCUNHO'"><label class="texto-direita">Caracteres restantes: <span>{{numMaxDescricaoPedido}}</span></label></span>
    <div class="br-textarea" style="font-weight: normal">
      <textarea
        class="Area"
        #detalhePedido
        name="detalhePedido"
        placeholder="Entre com os detalhes do Pedido"
        rows="5"
        [(ngModel)]="pedido.detalhePedido"
        required
        aria-required="true"
        [readonly] ="formularioReadonly()"
        maxlength="5000"        
        (keyup)="pressEnterDetalhePedido($event, detalhePedido.value)"
      ></textarea>
    </div>
  </div>
</div>

<div *ngIf="areaDaSaude()" class="row mt-4">
  <div class="col-sm-3">
    <label>Registro Anvisa</label>
    <span *ngIf="!pedido.isentoRegistroAnvisa" class="asterisco">*</span>
    <div style="font-weight: normal">
      <input
        #registroAnvisaInput="ngModel"
        type="text"
        pInputText
        placeholder="Entre com o Registro Anvisa"
        [(ngModel)]="pedido.registroAnvisa"
        [disabled]="pedido.isentoRegistroAnvisa === true"
        [readonly]="formularioReadonly()"
        [maxlength]="13"
        (keyup)="limparRegistroAnvisa()"
      />
    </div>
  </div>
  <br>
  <div class="col-sm-6 mt-5">
    <div class="br-checkbox">
      <input id="isentoRegistroAnvisa"
        name="isentoRegistroAnvisa"
        type="checkbox"
        aria-label="isento registro anvisa"
        [(ngModel)]="pedido.isentoRegistroAnvisa"
        (click)="isentoRegAnvisa()"
        [disabled] ="formularioReadonly()"
        />
      <label for="isentoRegistroAnvisa">Isento de Registro da Anvisa</label>
    </div>
  </div>
</div>
<!-- Ocultando o campo sustentável, a pedido do cliente - 16/07/2024
<div class="row mt-4" *ngIf="pedido.tipoPedido === 'M'">
  <div class="col-sm-2">
    <label>Sustentável</label>
    <div style="font-weight: normal">
        <p-dropdown [options]="optionsSimNao"
          optionLabel="nomeSustentavel"
          optionValue="value"
          [(ngModel)]="pedido.sustentavel"
          [readonly] ="formularioReadonly()">
        </p-dropdown>
    </div>
  </div>

  <div *ngIf="pedido.sustentavel === true" class="col-sm-10">
    <label>Justificativa Item Sustentável</label><span class="asterisco">*</span>

    <span *ngIf="pedido.situacaoPedido==='RASCUNHO'"><label class="texto-direita">Caracteres restantes: <span>{{numMaxJustificaItemSustentavel}}</span></label></span>
    <div class="br-textarea" style="font-weight: normal">
      <textarea
        class="Area"
        #justificativaItemSustentavel
        name="justificativaItemSustentavel"
        placeholder="Entre com a Justificativa para o Item Sustentável"
        rows="2"
        [(ngModel)]="pedido.justificativaSustentavel"
        required
        aria-required="true"
        [readonly]="formularioReadonly()"
        maxlength="2000"
        (keyup)="pressEnterJustificativa($event, justificativaItemSustentavel.value)"
      ></textarea>
    </div>
  </div>
</div> -->

<div *ngIf="pedido.tipoPedido === 'M'" class="row mt-4">

  <!-- Retirado a pedido do cliente - 04/06/2024 -->
  <!-- <div class="col-sm-3">
    <label>CNAE</label>
    <div style="font-weight: normal">
      <input
      type="number"
      class="p-inputtext"
      placeholder="Entre com o Código CNAE"
      [(ngModel)]="pedido.cnae"
      maxlength = "6"
      min="1"
      [readonly] ="formularioReadonly()"
      />
    </div>
  </div> -->

  <div class="col-sm-3">
    <label pTooltip="Informe o código NCM (campo opcional)">Código NCM</label>
    <div style="font-weight: normal">
      <input
        #ncmInput="ngModel"
        type="text"
        pInputText
        placeholder="Entre com o Código NCM"
        [readonly] ="formularioReadonly()"
        [(ngModel)]="pedido.codigoNcm"
        [maxlength]="8"
        (keyup)="limparNcm()"
      />
    </div>
    <div *ngIf="ncmInput.invalid" class="mb-3 mt-1">
      <span class="feedback danger" role="alert"><i class="fas fa-times-circle" aria-hidden="true"></i>O código deve conter apenas dígitos.</span>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col-sm-12">
    <label pTooltip="Inclua os links de referência (campo opcional)">Links de referência </label>
    <span *ngIf="pedido.situacaoPedido==='RASCUNHO'"><label class="texto-direita">Caracteres restantes: <span>{{numMaxLinkReferencia}}</span></label></span>
    <div class="br-textarea" style="font-weight: normal">
      <textarea
        class="Area"
        #linkReferencia
        name="linkReferencia"
        placeholder="Entre com os links de referência do Pedido"
        rows="3"
        [(ngModel)]="pedido.linkReferencia"
        [readonly] ="formularioReadonly()"
        maxlength="2000"
        (keyup)="pressEnterLinkReferencia($event, linkReferencia.value)"
      ></textarea>
    </div>
  </div>
</div>
<br>
</div>
