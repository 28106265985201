import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BuscaService } from 'src/app/service/busca-service';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manter-uasgs',
  templateUrl: './manter-uasgs.component.html',
  styleUrls: ['./manter-uasgs.component.css']
})
export class ManterUasgsComponent implements OnInit {

  visualizaCodigoUASG: number;
  visualizaCatalogadora: boolean;
  visualizaNome: string;
  visualizaNomeDesignacao: string;

  ativos: boolean = false;
  editaCodigoUASG: number;
  editaCatalogadora: boolean;
  editaNome: string;
  editaNomeDesignacao: string;

  numMaxNomeDesignacao: number = 60;
  incluirCodigoUASG: number | null;
  incluirNomeDesignacao: string | null;
  incluirCatalogadora: boolean | null;

  disableSave: any;

  filtroUasg: any;

  listaUasgs: any[];
  uasgs: any[];

  visible: boolean = false;
  modalRef1: BsModalRef<Object>;
  
  @ViewChild('uasgSucessoEditar', {static: false} ) uasgSucessoEditar: any;
  @ViewChild('uasgSucessoCriar', {static: false} ) uasgSucessoCriar: any;
  constructor(private router:Router, private service:BuscaService, private messageService: MessageService, private modalService: BsModalService,) { }

  ngOnInit(): void {
    this.pesquisar();
  }

  voltarTelaInicial(): void {
    this.router.navigate(['/arealogada']);
  }

  pesquisar() {
    this.service.obterUasgs().subscribe(data=>{
      this.listaUasgs = data;
      this.uasgs = data;
    })
  }

  enterPesquisar($event: KeyboardEvent) {
    if($event.key == "Enter"){
      this.pesquisar();
    }
  }

  filtrarUasg() {
    const regexp = new RegExp(this.filtroUasg, 'i');
    this.listaUasgs = this.uasgs.filter(uasg => {
      if (this.ativos) {
        return uasg.catalogador && regexp.test(uasg.nomeUasg);
      } else {
        return regexp.test(uasg.nomeUasg);
      }
    });
  }

  pegarDados(tipo:string, uasg: any) {
    if (tipo === 'visualiza') {
      this.visualizaCodigoUASG = uasg.codigoUasg;
      this.visualizaNome = uasg.nomeUasg;
      this.visualizaNomeDesignacao = uasg.nomeDesignacao;
      this.visualizaCatalogadora = uasg.catalogador;
    } else if (tipo === 'edita') {
      this.editaCodigoUASG = uasg.codigoUasg;
      this.editaNome = uasg.nomeUasg;
      this.editaNomeDesignacao = uasg.nomeDesignacao;
      this.editaCatalogadora = uasg.catalogador;
    }
  }

  enableSave() {
    throw new Error('Method not implemented.');
  }

  sairModalEditar() {
    this.modalRef1.hide();
    this.pesquisar();
  }

  somenteAtivos() {
    this.ativos = !this.ativos;
    this.filtrarUasg();
  }

  salvarUASG() {
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.visible = true;
    this.service.criarUASG({
      codigoUasg: this.incluirCodigoUASG,
      nomeDesignacao: this.incluirNomeDesignacao,
      catalogador: this.incluirCatalogadora,
    }).subscribe(_ => {
      this.visible = false;
      this.modalRef1 = this.modalService.show(this.uasgSucessoCriar, modalOptions);
    },
    erro => {
      this.visible = false;
      this.limpaCampos();
      if (typeof(erro.error) === 'string'){
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao salvar os dados no servidor: ' + erro.error
        });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
        });
      }
    });
  }

  sairModalSalvar() {
    this.limpaCampos();
    this.modalRef1.hide();
    this.pesquisar();
  }

  limpaCampos() {
    this.incluirCodigoUASG = null;
    this.incluirNomeDesignacao = '';
    this.incluirCatalogadora = false;
  }

  atualizarUASG() {
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.visible = true;
    this.service.atualizarUasg(this.editaCodigoUASG, {
      nomeDesignacao: this.editaNomeDesignacao,
      catalogador: this.editaCatalogadora,
    }).subscribe(_ => {
      this.visible = false;
      this.modalRef1 = this.modalService.show(this.uasgSucessoEditar, modalOptions);
    },
    erro => {
      this.visible = false;
      if (typeof(erro.error) === 'string'){
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao salvar os dados no servidor: ' + erro.error
        });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
        });
      }
    });
  }

  editaCatalogadoraToggle() {
    this.editaCatalogadora = !this.editaCatalogadora;
  }

  incluiCatalogadoraToggle() {
    this.incluirCatalogadora = !this.incluirCatalogadora;
  }

}
