<app-cabecalho-interno></app-cabecalho-interno> <br /><br /><br />
<div class="container-lg">
  <div class="mb-4 mt-2">
    <!-- cabeçalho -->
    <div class="row">
      <div class="col-8" id="cabecalho">
        <h2 class="mb-0" aria-label="Manter Unidade de Fornecimento">
            <span class="mouseover">Catálogo - Catalogador</span>
        </h2>
        <h6 style="font-weight: 400" class="mb-0 mt-1">
        Use esta área para incluir/editar dados de Catalogadores
        </h6>
      </div>

      <div class="col-4 text-right row" style="align-items: end">
        <div class="col-sm-6 card border-0">
          <button
            class="br-button is-secondary"
            type="button"
            (click)="voltarTelaInicial()"
          >
            Voltar
          </button>
        </div>
        <div class="col-sm-6 card border-0">
          <br-loading [visible]="visible" label="Salvando ..."></br-loading>
          <button
            class="br-button is-primary"
            type="button"
            data-toggle="modal"
            (click)="abrirCriar()"
            data-target="#incluirCatalogador"
          >
            Incluir
          </button>
        </div>
      </div>
    </div>
  </div>

  <hr />


  <div *ngIf="escondeValor != true">
    <p-table
      [value]="listaCatalogadores"
      [rows]="10"
      [paginator]="true"
      responsiveLayout="scroll"
      [rowHover]="true"
      dataKey="id"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} entradas"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
    >
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between row">
          <h5 class="ml-3 mt-0">Catalogadores</h5>

          <div class="br-checkbox">  <!-- A FAZER - JMD -->
            <input id="somenteAtivos" name="somente-ativos" type="checkbox" aria-label="somente ativos" (click)="somenteAtivos()" [(ngModel)]="ativos"/>
            <label for="somenteAtivos">Mostrar somente catalogadores ativos</label>
          </div>

          <div class="br-input col-5 mb-3">
            <span class="mt-5"> </span>
            <input
              [(ngModel)]="filtroCatalogador"
              (keyup)="filtrarCatalogador()"
              class="p-inputtext"
              placeholder="Filtrar catalogador por nome ou UASG"
            />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="cpfCatalogador">
            CPF<p-sortIcon field="cpfCatalogador"></p-sortIcon>
          </th>
          <th pSortableColumn="uasgCatalogador">
            UASG<p-sortIcon field="uasgCatalogador"></p-sortIcon>
          </th>
          <th pSortableColumn="nomeCatalogador">
            Nome <p-sortIcon field="nomeCatalogador"></p-sortIcon>
          </th>
          <th pSortableColumn="ativo">
            Ativo <p-sortIcon field="ativo"></p-sortIcon>
          </th>
          <th>Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-catalogador>
        <tr>
          <td>
            <span style="color: black;" >{{ catalogador.cpfCatalogador }}</span>
          </td>
          <td>
            <span  style="color: black;" >{{ catalogador.codigoUasgCatalogador }}</span>
          </td>
          <td>
            <span  style="color: black;" >{{ catalogador.nomeCatalogador }}</span>
          </td>
          <td>
            <span style="color: black;" >{{ catalogador.ativo ? "Sim" : "Não" }}</span>
          </td>
          <td>
            <button
              class="br-button"
              data-toggle="modal"
              data-target="#visualizarCatalogador"
              aria-label="Botão de ação"
              pTooltip="Visualizar"
              (click)="pegarDados('visualiza', catalogador)"
              tooltipPosition="top"
            >
              <i class="fas fa-eye" aria-hidden="true"></i>
            </button>
            <button
              class="br-button"
              data-toggle="modal"
              type="button"
              data-target="#editarCatalogador"
              pTooltip="Editar"
              (click)="pegarDados('edita', catalogador)"
              tooltipPosition="top"
            >
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!--  MODAIS  -->

  <div
    id="incluirCatalogador"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Incluir Catalogador</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row mt-3">
            <label class="col-9">
              CPF<span class="asterisco">*</span>
            </label>
          </div>
          <div class="br-input">
            <input #campoCpf
              [(ngModel)]="incluirCpf"
              placeholder="Digite o CPF"
              maxlength="11"
            />
          </div>
          <div class="row mt-3">
            <label class="col-9">
              Nome<span class="asterisco">*</span>
            </label>
          </div>
          <div class="br-input">
            <input #campoNome
              [(ngModel)]="incluirNome"
              placeholder="Digite o nome do catalogador"
              maxlength="70"
            />
          </div>
          <div class="row mt-3">
            <label class="col-9">
              E-mail<span class="asterisco">*</span>
            </label>
          </div>
          <div class="br-input">
            <input #campoUasgCatalogador
              [(ngModel)]="incluirEmail"
              placeholder="Digite e-mail do catalogador"
              maxlength="50"
            />
          </div>
          <div class="row mt-3">
            <label class="col-9">
              UASG<span class="asterisco">*</span>
            </label>
          </div>
          <div class="br-input">
            <input #campoUasgCatalogador
              [(ngModel)]="incluirUasgCatalogador"
              placeholder="Digite o código da UASG do catalogador"
              maxlength="6"
            />
          </div>
        
        </div>
        <div class="modal-footer">
          <div>
            <button
              class="br-button secondary"
              data-dismiss="modal"
              (click)="limpaCampos()"
            >
              Voltar
            </button>
          </div>
          <div>
            <button
              class="br-button primary"
              [disabled]="disableSave"
              (click)="salvarNovoCatalogador()"
              data-dismiss="modal"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="editarCatalogador"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Editar Catalogador</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <br />
        <div class="container-lg">
          <div class="row">
            <div class="br-input mt-3 col-md-6">
              <label>CPF</label>
              <input [(ngModel)]="editaCpfCatalogador" readonly />
            </div>
            <div class="col-md-6 align-self-center" style="text-align: end" (click)="editaAtivoToggle()">
              <div
                class="br-switch medium switch-basic"
                style="margin-left: 10px"
              >
                <input type="checkbox" [(ngModel)]="editaCatalogadorAtivo"/>
                <label for="editaCatalogadorAtivo">Ativo</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-3">
            <label>Nome</label>
            <input [(ngModel)]="editaNomeCatalogador" />
          </div>
          <div class="br-input mt-3">
            <label>E-mail</label>
            <input [(ngModel)]="editaEmailCatalogador" />
          </div>
          <div class="br-input mt-3 mb-5">
            <label>UASGs</label>
            <input [(ngModel)]="editaUasgCatalogador" />
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
          <div>
            <button
              class="br-button primary"
              (click)="atualizarCatalogador()"
              data-dismiss="modal"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="visualizarCatalogador"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Catalogador</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row">
            <div class="br-input mt-3 col-md-6">
              <label>CPF</label>
              <input [(ngModel)]="visualizaCpfCatalogador" readonly />
            </div>
            <div class="col-md-6 align-self-center" style="text-align: end">
              <div
                class="br-switch medium switch-basic"
                style="margin-left: 10px"
              >
                <input type="checkbox" [(ngModel)]="visualizaCatalogadorAtivo" readonly />
                <label for="visualizaCatalogadora">Ativo</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-3">
            <label>Nome</label>
            <input [(ngModel)]="visualizaNomeCatalogador" readonly />
          </div>
          <div class="br-input mt-3">
            <label>E-mail</label>
            <input [(ngModel)]="visualizaEmailCatalogador" readonly />
          </div>
          <div class="br-input mt-3 mb-5">
            <label>UASG</label>
            <input [(ngModel)]="visualizaUasgCatalogador" readonly />
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #catalogadorSucessoIncluir>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Catalogador incluído com sucesso</h4>
        <button type="button" class="close" (click)="sairModalSalvar()">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div
          class="flex justify-content-center mb-5 mt-5"
          style="text-align: center"
        >
          <label>Catalogador incluído com sucesso.</label><br />
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalSalvar()">
            Voltar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #catalogadorSucessoEditar>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">Catalogador editado com sucesso</h4>
        <button type="button" class="close" (click)="sairModalEditar()">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div
          class="flex justify-content-center mb-5 mt-5"
          style="text-align: center"
        >
          <label>Catalogador editado com sucesso</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalEditar()">
            Voltar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

</div>