<!-- Página: historico-analise-pedido-component.html -->
<div class="col">
<div class="row">
    <div class="col-md-6">
      <h3 class=" mt-0 mb-0 pt-3 pb-0">Comunicação</h3>
      <p></p>
    </div>
</div>

<div class="br-divider mt-2"></div>

<div class="br-textarea" style="font-weight: normal" *ngIf="pedido.situacaoPedido!=='FINALIZADO'">
<div class="d-flex mt-3">
  <label>Análise</label><span class="asterisco">*</span>
  <label class="ml-auto">Caracteres restantes: <span>{{maxLengthChat - chatArea.length}}</span></label>
</div>

  <textarea
    class="Area"
    placeholder="Digite a Análise"
    rows="5"
    [(ngModel)]="chatArea"
    [maxlength]=maxLengthChat
    required
  ></textarea>
  <div class="d-flex flex-row-reverse justify-content-between">
    <div class="pl-2 pt-3 pb-2">
      <button type="button" class="br-button is-primary small" [attr.disabled]="chatArea=='' ? 'disabled' : null" (click)="clickEnviarMensagem()">
        Salvar
      </button>
    </div>
    <div class="pt-1 message">
      Para devolver o pedido à unidade catalogadora, selecione “Informações Básicas” e clique em “Enviar”.
    </div>
  </div>
</div>



<br>
<div *ngFor="let hist of historico" style="margin-left:7px;">
  <p>
    <span *ngIf="usrProfile==='SOLICITANTE' && hist.codigoUasg===null" class="blue">{{hist.nomeUsuario}} - </span>
    <span *ngIf="usrProfile==='SOLICITANTE' && hist.codigoUasg" class="blue">{{hist.nomeUasg}} - </span>
    <span *ngIf="usrProfile!='SOLICITANTE'" class="blue">{{hist.nomeUsuario}} - </span>
    {{ dataFormatada(hist.dataHoraRegistro) }}
    <br>
    <span>{{hist.mensagem}}</span>
  </p>
</div>
<label *ngIf="historico?.length == 0">
   Não há comunicação.
</label>
</div>
