
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessaoService } from 'src/app/service/sessao.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { MenuItem, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { FiltroService } from '../../service/filtro.service';
import { NovasTabelasPedidosCatalogadorComponent } from './tabela-pedidos/tabela-pedidos.component';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../security/services/auth-service.service';
import { EnvironmentUtil } from 'src/environments/EnviromentUtil';

@Component({
  selector: 'app-area-catalogador',
  templateUrl: './area-catalogador.component.html',
  styleUrls: ['./area-catalogador.component.css']
})
export class AreaCatalogadorComponent implements OnInit {

  modalFiltroPedidosRef: BsModalRef;
  @ViewChild('modalFiltroPedido', { static: false }) modalFiltroPedido: any;

  @ViewChild('listaDesignadosAMim', { static: false }) listaDesignadosAMim: NovasTabelasPedidosCatalogadorComponent;
  @ViewChild('listaDesignadosAMinhaUasg', { static: false }) listaDesignadosAMinhaUasg: NovasTabelasPedidosCatalogadorComponent;
  @ViewChild('listaPedidosAbertos', { static: false }) listaPedidosAbertos: NovasTabelasPedidosCatalogadorComponent;

  @ViewChild('listaTodos', { static: false }) listaTodos: NovasTabelasPedidosCatalogadorComponent;

  @ViewChild('listaMeusPedidos', { static: false }) listaMeusPedidos: NovasTabelasPedidosCatalogadorComponent;
  @ViewChild('listaPedidosDaMinhaUasg', { static: false }) listaPedidosDaMinhaUasg: NovasTabelasPedidosCatalogadorComponent;

  itemsmenubotao: MenuItem[];
  itemsmenuconsultas: MenuItem[];

  DESIGNADOS_A_MIM = ABA.DESIGNADOS_A_MIM;
  DESIGNADOS_A_MINHA_UASG = ABA.DESIGNADOS_A_MINHA_UASG;
  PEDIDOS_ABERTOS = ABA.PEDIDOS_ABERTOS;

  TODOS = ABA.TODOS;

  MEUS_PEDIDOS = ABA.MEUS_PEDIDOS;
  PEDIDOS_DA_MINHA_UASG = ABA.PEDIDOS_DA_MINHA_UASG;

  filtro: FiltroPedido;
  situacaoOptions = [
    { value: 'RASCUNHO', label: 'Rascunho' },
    { value: 'ABERTO', label: 'Aberto' },
    { value: 'DESIGNADO', label: 'Designado para o catalogador' },
    { value: 'EM_ANALISE', label: 'Em análise' },
    { value: 'AGUARDANDO_SOLICITANTE', label: 'Aguardando Solicitante' },
    { value: 'FINALIZADO', label: 'Finalizado' }
  ]

  catalogadorCarregados: any[]; // Catalogadores carregados p/ uso no filtro

  catalogadorOptions: any[]; // Catalogadores que efetivamente aparecem no filtro (pode selecionar somente ativos ou não)
  uasgSolicitanteOptions: any[];
  uasgCatalogadoraOptions: any[];
  perfil: string;
  url: string;
  filtroAtivado: boolean;

  constructor(
    private router: Router,
    private sessaoService: SessaoService,
    private modalService: BsModalService,
    private messageService: MessageService,
    private filtroService: FiltroService,
    private authService: AuthService
  ) { }

  loading(): boolean {

    if (this.isSolicitante()) {
      return this.listaMeusPedidos?.loading
      || this.listaPedidosDaMinhaUasg?.loading;
    }

    if (this.isAdministrador()) {
      return this.listaPedidosAbertos?.loading
      || this.listaDesignadosAMim?.loading
      || this.listaDesignadosAMinhaUasg?.loading
      || this.listaTodos?.loading;
    }

    return this.listaPedidosAbertos?.loading
    || this.listaDesignadosAMim?.loading
    || this.listaDesignadosAMinhaUasg?.loading;

  }

  getAbaTitulo(aba: ABA): string {
    return aba === ABA.DESIGNADOS_A_MIM ? 'Designados a mim' :
      aba === ABA.DESIGNADOS_A_MINHA_UASG ? 'Designados a minha Unidade' :
      aba === ABA.PEDIDOS_ABERTOS ? 'Pedidos abertos' :
      aba === ABA.TODOS ? 'Todos os pedidos' :
      aba === ABA.MEUS_PEDIDOS ? 'Meus Pedidos' :
      aba === ABA.PEDIDOS_DA_MINHA_UASG ? 'Pedidos da minha Unidade' :
      '';
  }

  isSolicitante() {
    return this.perfil ? this.perfil === 'SOLICITANTE' : false;
  }

  isAdministrador() {
    return this.perfil ? this.perfil === 'ADMINISTRADOR' : false;
  }

  isCatalogador() {
    return this.perfil ? this.perfil === 'CATALOGADOR' : false;
  }

  ngOnInit(): void {

    this.perfil = this.authService.token? this.authService.getUserProfile(this.authService.token) : '';

    this.filtro = new FiltroPedido();
    this.carregarTelaFiltro();

    if (!this.isSolicitante()) {
      this.carregarItensMenu();
    }
  }

  carregarItensMenu() {
    this.itemsmenubotao = [{
      items: [
        {
          label: 'Serviço',
          command: () => {
            this.router.navigate(['/buscaservico']);
          }
        },
        {
          label: 'Material',
          command: () => {
            this.router.navigate(['/buscamaterial']);
          }
        },
        {
          label: 'Característica',
          command: () => {
            this.router.navigate(['/mantercaracteristica']);
          }
        },
        {
          label: 'Valor de característica',
          command: () => {
            this.router.navigate(['/mantervalorcaracteristica']);
          }
        },
        {
          label: 'Unidade de Fornecimento',
          command: () => {
            this.router.navigate(['/manterunidadefornecimento']);
          }
        },
        {
          label: 'Unidade de Medida',
          command: () => {
            this.router.navigate(['/manterunidademedida']);
          }
        },
        {
          label: 'Uasg',
          command: () => {
            this.router.navigate(['/manteruasg']);
          }
        },
        {
          label: 'Catalogador',
          command: () => {
            this.router.navigate(['/mantercatalogador']);
          }
        }
      ]
    }
    ];

    this.itemsmenuconsultas = [{
      items: [
        {
          label: 'Catálogo',
          command: () => {
            this.router.navigate([]).then(result => {
              window.open(EnvironmentUtil.obterUrlAplicacao() + '/busca', '_blank'); });
          }
        },
        {
          label: 'Pedidos legados',
          command: () => {
            this.router.navigate([]).then(result => {
              window.open(EnvironmentUtil.obterUrlAplicacao() + '/consulta-pedidos-legados', '_blank'); });
          }
        }
      ]
    }
    ];
  }

  setCatalogadorOptions(){
    // Constrói a lista de catalogadores para popular o filtro
    this.catalogadorOptions = this.catalogadorCarregados.filter( catalogador => 
      this.filtro.catalogadorAtivo ? catalogador.ativo : catalogador );
  }

  carregarTelaFiltro() {

    if (!this.isSolicitante()) {
      this.filtroService.listarCatalogadores().subscribe(
        catalogadores => {
          this.catalogadorCarregados = catalogadores.map( catalogador =>{
            return {
              label: catalogador.nomeCatalogador,
              value: catalogador.cpfCatalogador,
              ativo: catalogador.ativo
            }
          });
          this.setCatalogadorOptions();
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao carregar Catalogadores.'
          });
        }
      );
      this.catalogadorOptions = this.catalogadorCarregados;
      this.filtroService.listarUasgs().subscribe(
        uasgs => {
          this.uasgCatalogadoraOptions = uasgs.map(uasg => {
            return {
              label: uasg.codigoUasg + ' - ' + uasg.nomeUasg,
              value: uasg.codigoUasg
            };
          })
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao carregar Unidades Catalogadoras.'
          });
        }
      );
    }
}

irParaCatalogo() {
  this.router.navigate([]).then(result => {
    window.open(EnvironmentUtil.obterUrlAplicacao() + '/busca', '_blank'); });
  //this.router.navigate(['/busca']);
}

irParaAT() {
  this.sessaoService.irParaAT();
}

enterPesquisar($event: KeyboardEvent) {
  if ($event.key === 'Enter') {
    this.buscarPorTexto();
  }
}

buscarPorTexto() {
  if (this.filtro) {
    this.filtroService.anunciarFiltroAlterado(this.filtro);
  }
}

limparFiltro() {
  this.filtro = new FiltroPedido();
  this.setCatalogadorOptions();
  this.modalService.hide();
  this.filtroService.anunciarFiltroAlterado(this.filtro);
  this.filtroAtivado = false;
}

abrirModalFiltroPedido() {
  const modalOptions: ModalOptions = { class: 'modal-lg' };
  this.modalFiltroPedidosRef = this.modalService.show(this.modalFiltroPedido, modalOptions);
}

aplicarFiltro() {
  this.filtroAtivo();
  this.modalService.hide();
  this.filtroService.anunciarFiltroAlterado(this.filtro);
}

fechaModalFiltro() {
  this.modalService.hide();
}

filtroAtivo() {
  let ret: boolean;
  ret = (this.filtro.catalogador == null || this.filtro.catalogador == undefined) &&
  (this.filtro.dataFinal == ""  || this.filtro.dataFinal == undefined) &&
  (this.filtro.dataInicio == "" || this.filtro.dataInicio == undefined) &&
  (this.filtro.dataFinalAtualizacao == ""  || this.filtro.dataFinalAtualizacao == undefined) &&
  (this.filtro.dataInicioAtualizacao == "" || this.filtro.dataInicioAtualizacao == undefined) &&
  (this.filtro.numero == null || this.filtro.numero == undefined) &&
  (this.filtro.situacao  == null || this.filtro.situacao == undefined) &&
  (this.filtro.textoPesquisa  == null || this.filtro.textoPesquisa == undefined) &&
  (this.filtro.uasgCatalogadora  == null || this.filtro.uasgCatalogadora == undefined) &&
  (this.filtro.uasgSolicitante  == null || this.filtro.uasgSolicitante == undefined);
  // return this.filtro && Object.keys(this.filtro).length > 0;
  this.filtroAtivado = !ret;
}

}

export enum ABA {

  DESIGNADOS_A_MIM = "DESIGNADOS_A_MIM",
  DESIGNADOS_A_MINHA_UASG = "DESIGNADOS_A_MINHA_UASG",
  PEDIDOS_ABERTOS = "PEDIDOS_ABERTOS",
  TODOS = "TODOS",
  MEUS_PEDIDOS = "MEUS_PEDIDOS",
  PEDIDOS_DA_MINHA_UASG = "PEDIDOS_DA_MINHA_UASG"

}

export class FiltroPedido {

  dataInicio: string;
  dataFinal: string;
  dataInicioAtualizacao: string;
  dataFinalAtualizacao: string;
  catalogador: string;
  catalogadorAtivo: boolean;
  uasgCatalogadora: string;
  uasgSolicitante: string;
  numero: string
  situacao: string;
  textoPesquisa: string;

  public constructor(){
    this.catalogadorAtivo=true;
  }
  public FiltroPedido() {}

}
