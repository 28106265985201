import { VisualizarMaterialComponent } from './catalogador/visualizar-material/visualizar-material.component';
import { CriarMaterialComponent } from './catalogador/criar-material/criar-material.component';
import { BuscaMaterialComponent } from './catalogador/busca-material/busca-material.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// import {CatalogadorComponent} from './catalogador/busca-catalogador/catalogador.component';
// import {ArealogadaComponent} from './arealogada/arealogada.component';
import {CompradorComponent} from './comprador/comprador.component';
import {LoginFakeComponent} from './login-fake/login-fake.component';
import {IniciarSessaoComponent} from './iniciar-sessao/iniciar-sessao.component';
import {SessaoEncerradaComponent} from './sessao-encerrada/sessao-encerrada.component';
import {TipoUsuarioEnum} from '../security/model/tipo-usuario-enum';
import {AuthGuard} from '../security/guards/auth.guard';
import {LoginFakeGuard} from '../security/guards/login-fake.guard';
import {ManterMaterialComponent} from './catalogador/manter-material/manter-material.component';
import {ManterServicoComponent } from './catalogador/manter-servico/manter-servico.component';
import {ManterCaracteristicaComponent} from './catalogador/manter-caracteristica/manter-caracteristica.component';
import { ManterValorCaracteristicaComponent } from './catalogador/manter-valor-caracteristica/manter-valor-caracteristica.component';
import { ManterUnidadeFornecimentoComponent } from './catalogador/manter-unidade-fornecimento/manter-unidade-fornecimento.component';
import { ManterUnidadeMedidaComponent } from './catalogador/manter-unidade-medida/manter-unidade-medida.component';
import { BuscaServicoComponent } from './catalogador/busca-servico/busca-servico.component';
import { CriarServicoComponent } from './catalogador/criar-servico/criar-servico.component';
import { VisualizarServicoComponent } from './catalogador/visualizar-servico/visualizar-servico.component';
import { ManterItemMaterialComponent } from './catalogador/manter-item-material/manter-item-material.component';
import { ManterPedidosComponent } from './comprador/manter-pedidos/manter-pedidos.component';
// import { PedidoCatalogadorComponent } from './catalogador/Pedidos/pedido-catalogador/pedido-catalogador.component';
// import { ManterPedidosCatalogadorComponent } from './catalogador/Pedidos/manter-pedidos-catalogador/manter-pedidos-catalogador.component';
import { AreaCatalogadorComponent } from './catalogador/area-catalogador/area-catalogador.component';
import { PedidoComponent } from './pedido/pedido.component';
import { Mnemonicos } from '../security/model/mnemonicos.enum';
import { ManterUasgsComponent } from './catalogador/manter-uasgs/manter-uasgs.component';
import { ManterCatalogadorComponent } from './catalogador/manter-catalogador/manter-catalogador.component';

const routes: Routes = [
  // {
  //   path: 'arealogada', component: ArealogadaComponent,
  //   canActivate: [AuthGuard],
  //   canActivateChild: [AuthGuard],
  //     data: {
  //       expectedRole: TipoUsuarioEnum.GOVERNO
  //     }
  //   },
  {
    path: 'iniciar-sessao',
    component: IniciarSessaoComponent
  },
  { path: 'loginfake',
    component: LoginFakeComponent,
    canActivate: [LoginFakeGuard],
    canActivateChild: [LoginFakeGuard],
  },
  {
    path: 'sessao-encerrada',
    component: SessaoEncerradaComponent
  },
  {
    path: 'arealogada', component: AreaCatalogadorComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      expectedRole: TipoUsuarioEnum.GOVERNO
    }
  },
  {
    path: 'consulta-pedidos-legados', component: CompradorComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      expectedRole: TipoUsuarioEnum.GOVERNO,
      expectedMnemonicos: [Mnemonicos.ANALISAPED, Mnemonicos.CATADM, Mnemonicos.CATUASG]
    }
  },
  // {
  //   // path: 'areacomprador', component: PedidoCatalogadorComponent,//TODO: destrocar os nomes dos componentes (renomear) (Catalogador x Comprador)
  //   path: 'areacomprador', component: AreaCatalogadorComponent,//TODO: remover essa rota após refactoring
  //   canActivate: [AuthGuard],
  //   canActivateChild: [AuthGuard],
  //   data: {
  //     expectedRole: TipoUsuarioEnum.GOVERNO
  //   }
  // },


  {
    path: 'criarmaterial', component: CriarMaterialComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      expectedRole: TipoUsuarioEnum.GOVERNO,
      expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
    }
  },

  {
    path: 'mantercaracteristica', component: ManterCaracteristicaComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      expectedRole: TipoUsuarioEnum.GOVERNO,
      expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
    }
  },

  {
    path: 'mantervalorcaracteristica', component: ManterValorCaracteristicaComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      expectedRole: TipoUsuarioEnum.GOVERNO,
      expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
    }
  },

    {
      path: 'manterunidadefornecimento', component: ManterUnidadeFornecimentoComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
      }
    },

    {
      path: 'manterunidademedida', component: ManterUnidadeMedidaComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM]
      }
    },

    {
      path: 'manteruasg', component: ManterUasgsComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
      }
    },
    {
      path: 'mantercatalogador', component: ManterCatalogadorComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
      }
    },
    {
      path: 'buscamaterial', component: BuscaMaterialComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
      }
    },
    {
      path: 'buscaservico', component: BuscaServicoComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
      }
    },
    {
      path: 'manterservico/:id', component: ManterServicoComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
      }
    },
    {
      path: 'criarservico', component: CriarServicoComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
      }
    },
    {
      path: 'visualizarservico/:id', component: VisualizarServicoComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
      }
    },
    {
      path: 'mantermaterial/:id', component: ManterMaterialComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
      }
    },
    {
      path: 'manteritemmaterial/:id/:nomePdm', component: ManterItemMaterialComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
      }
    },
    {
      path: 'visualizarmaterial/:id', component: VisualizarMaterialComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
      data: {
        expectedRole: TipoUsuarioEnum.GOVERNO,
        expectedMnemonicos: [Mnemonicos.CATADM, Mnemonicos.CATUASG]
      }
    },
    {
      path: 'manter-pedidos/:id',
      component: ManterPedidosComponent,//TODO: destrocar os nomes dos componentes (renomear) (Catalogador x Comprador)
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
        data: {
          expectedRole: TipoUsuarioEnum.GOVERNO,
          expectedMnemonicos: [Mnemonicos.ANALISAPED, Mnemonicos.CATADM, Mnemonicos.CATUASG]
        }
    },
    // {
    //   path: 'manter-pedidos-catalogador/:id',
    //   component: ManterPedidosCatalogadorComponent,//TODO: destrocar os nomes dos componentes (renomear) (Catalogador x Comprador)
    //   canActivate: [AuthGuard],
    //   canActivateChild: [AuthGuard],
    //     data: {
    //       expectedRole: TipoUsuarioEnum.GOVERNO
    //     }
    // },
    {
      path: 'pedido/:numeroPedido',
      component: PedidoComponent,
      canActivate: [AuthGuard],
      canActivateChild: [AuthGuard],
        data: {
          expectedRole: TipoUsuarioEnum.GOVERNO
        }
    }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ArealogadaRoutingModule{}
